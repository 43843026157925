body {
  font-family: "Roboto", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  line-height: 1.5em;
}

iframe {
  border-radius: 20px;
  max-width: 100%;

  @media only screen and (max-width: 600px) {
    margin-bottom: 3rem;
  }
}
body .App {
  padding: 0;
  top: 0;
  left: 0;
  .top {
    background-image: url("./images/bg.jpg");
    background-position: center;
    background-size: cover;
    min-height: 600px;

    nav {
      background-color: rgba(0, 0, 0, 0.55);

      .nav-wrapper {
        display: flex;
        max-width: 1240px;
        margin: 0 auto;
        padding: 25px 0px;
        justify-content: space-between;
        align-items: center;
        img {
          max-width: 150px;
        }

        @media only screen and (max-width: 600px) {
          justify-content: center;
        }

        ul {
          display: flex;
          color: #fff;

          @media only screen and (max-width: 600px) {
            display: none;
          }

          li {
            list-style-type: none;
            margin-left: 30px;
            text-decoration: none;

            a {
              color: #fff;
              text-decoration: none;
              text-transform: uppercase;

              &:hover {
                border-bottom: 3px solid #FF5398;
                cursor: pointer;
              }
            }
          }
        }
      }

      ul {
        padding: 0;
        margin: 0;
      }
    }

    #location {
      color:#fff;
    }

    .container {
      max-width: 1240px;
      padding: 50px;
      margin: 0px auto;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding-top:15px;

      @media only screen and (max-width: 600px) {
        padding: 20px;
      }

      .buttonContainer {
        width: 100%;
        display: flex;
        .btn {
          background-color: #9B2DD2;
          color: #fff;
          padding: 15px 30px;
          border-radius: 5px;
          font-size: 20px;
          border: none;
          margin: 0px auto;
          margin-top: 40px;
          text-decoration: none;

          &:hover {
            opacity: 0.8;
            cursor: pointer;
          }
        }
      }
    }

    h2 {
      font-size: 4.3rem;
      padding: 0;
      color: #fff;
      font-weight: 900;
      opacity: 0.9;
      margin-bottom: 30px;
      width: 100%;
      text-align: center;
      margin-top: 10px;

      @media only screen and (max-width: 600px) {
        font-size: 3rem;
      }
    }

    h4 {
      font-size: 1.5rem;
      line-height: 1.6em;
      padding: 0;
      color: #fff;
      font-weight: 300;
      opacity: 0.8;
      width: 70%;
      text-align: center;

      @media only screen and (max-width: 600px) {
        width: 90%;
        font-size: 1.3rem;
      }
    }
  }

  .site-container {
    max-width: 1240px;
    margin: 0px auto;
    margin-top: 40px;
    padding-bottom: 100px;

    .box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      h2 {
        border-bottom: 8px solid #FF5398;
        opacity: 0.7;
        margin-bottom: 20px;
      }

      p {
        font-size: 18px;
      }

      .left,
      .right {
        width: 35%;
      }

      .right img {
        margin-top: 50px;
        border-radius: 60px;
        max-width: 100%;
      }

      .left {
        width: 55%;
      }

      .left,
      .right {
        @media only screen and (max-width: 600px) {
          width: 100%;
          padding: 0px 30px;
        }
      }

      .center {
        text-align: center;
        width: 100%;
        margin-top: 50px;
        h2 {
          border: none;
        }

        p {
          width: 40%;
          margin: 0px auto;
        }
      }
    }
    h2 {
      font-size: 35px;
    }
  }

  .box-pink {
    background-color: #FF5398;
    padding: 20px 0px;
    padding-bottom: 60px;
    color: #fff;
    h2 {
      font-size: 35px;
      color:#fff;
      opacity: 1 !important
    }
    .container {
      max-width: 1240px;
      margin: 0px auto;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    h2 {
      border-bottom: 8px solid #fff;
      opacity: 0.7;
      margin-bottom: 20px;
    }

    p {
      font-size: 18px;
    }

    .left,
    .right {
      width: 35%;
    }

    .right img {
      margin-top: 50px;
      border-radius: 60px;
      max-width: 100%;
    }

    .left {
      width: 55%;
    }

    .left,
    .right {
      @media only screen and (max-width: 600px) {
        width: 100%;
        padding: 0px 30px;
      }
    }
  }
}
